import {
  ConfigEntities,
  DisplayMagnitude,
  QtyBasisSources,
} from '@prisma/client';
import { z } from 'zod';

export const quotingConfigCreateSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  pricingConfigId: z.string().min(1, { message: 'Pricing config is required' }),
  costingConfigId: z.string().min(1, { message: 'Costing config is required' }),
});

export type QuotingConfigCreateDefaultFormValues = z.input<
  typeof quotingConfigCreateSchema
>;
export type QuotingConfigCreateFormData = z.output<
  typeof quotingConfigCreateSchema
>;

export const quotingConfigUpdateSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  configEntityPriorities: z.array(z.nativeEnum(ConfigEntities)),
  basisQtySrc: z.array(z.nativeEnum(QtyBasisSources)),
  pricingConfigId: z.string(),
  costingConfigId: z.string(),
  daysUntilExpiration: z.number().or(z.string().pipe(z.coerce.number().gte(0))),
  daysUntilExpectedWin: z
    .number()
    .or(z.string().pipe(z.coerce.number().gte(0))),
  quantityDisplayMagnitude: z.nativeEnum(DisplayMagnitude),
  discountCodeDefault: z.string().nullable(),
  marketCodeDefault: z.string().nullable(),
  defaultWonLossStatus: z.string().nullable(),
});

export type QuotingConfigUpdateDefaultFormValues = z.input<
  typeof quotingConfigUpdateSchema
>;
export type QuotingConfigUpdateFormData = z.output<
  typeof quotingConfigUpdateSchema
>;
